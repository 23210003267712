<template>
	<div class="wrapper">
		<section class="wrapper_top">預約成功</section>
		<section class="wrapper_centent">
			<div class="top_icon"><img :src="require('@/assets/image/state_cg.png')" alt="" /></div>

			<div class="top_text">恭喜你，預約成功</div>

			<div class="top_number">
				<span>您的考生號為：</span>
				<span>{{ renderData.testCode }}</span>
				<div @click="copyBut">複製</div>
			</div>
			
			<div class="uls">
				<div class="lis lis_hr">
					<span>證件姓名</span>
					<div>{{renderData.testName}}</div> 
				</div>
				<div class="lis lis_hr">
					<span>證件英文姓名</span>
					<div>{{renderData.testNamePinyin}}</div> 
				</div>
				<div class="lis lis_hr">
					<span>手機號</span>
					 <div>+{{ renderData.areaCode }}-{{ renderData.phone }}</div> 
				</div> 
				<div class="lis lis_hr">
					<span>電郵</span>
					<div>{{renderData.email}}</div> 
				</div>
				<div class="lis">
					<span>預約時間</span>
					<div>{{ renderData.testDate.split(' ')[0] }}&nbsp;{{ renderData.testStartTime }}</div> 
				</div>
			</div>
			
			<div class="uls"> 
				<div class="lis lis_hr">
					<span>證件類型</span>
					<div>{{ renderData.idType === 0 ? '身份證' : renderData.idType == 1 ? '護照' : null }}</div> 
				</div>
				<div class="lis">
					<span>證件號</span>
					<div>{{ renderData.idNo }}</div> 
				</div>
			</div>
		</section>
	</div>
</template>

<script>
export default {
	components: {},
	name: 'reservationFormState',
	data() {
		return { 
			renderData:{},
		};
	},
	watch: {},
	components: {},
	created() {},
	mounted() {
		console.log(this.$route.params.data)
		this.renderData = this.$route.params.data
	// 	// 禁用浏览器返回键 
	// 　　history.pushState(null, null, document.URL); 
	// 　　window.addEventListener('popstate', this.disableBrowserBack);
	},
	destroyed(){
		// 清除popstate事件 否则会影响到其他页面 
		// window.removeEventListener("popstate", this.disableBrowserBack, false);
	},
	computed: {},
	methods: {
		disableBrowserBack() { 
		// 　history.pushState(null, null, document.URL); //禁止返回
			history.go(-2);//返回首页
		},
		copyBut() {
			this.copy(this.renderData.testCode);
		},
		copy(e) {
			var input = document.createElement('input'); // 直接构建input
			input.value = e; // 设置内容
			document.body.appendChild(input); // 添加临时实例
			input.select(); // 选择实例内容
			document.execCommand('Copy'); // 执行复制
			document.body.removeChild(input); // 删除临时实例
			this.$toast('复制成功');
		}
	}
};
</script>
<style lang="scss">
@import '@/assets/css/index.scss';


</style>

<style lang="scss" scoped>
@import '@/assets/css/index.scss';

.wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	overflow-x: hidden;
	background: #fafafa;
	padding-top: px2rem(80);
	box-sizing: border-box;

	.wrapper_top {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: px2rem(80);
		text-align: center;
		line-height: px2rem(80);
		font-size: px2rem(36);
		background: #ffffff;
		font-family: PingFang SC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
	}

	.wrapper_centent {
		width: 100%;
		padding: px2rem(32);
		padding-top: px2rem(48);
		box-sizing: border-box;

		.top_icon {
			width: px2rem(136);
			height: px2rem(136);
			border-radius: px2rem(100);
			margin: auto;
			box-shadow: 0px px2rem(8) px2rem(80) 0px rgba(18, 42, 176, 0.2);

			img {
				width: 100%;
				height: 100%;
			}
		}

		.top_text {
			margin-top: px2rem(24);
			text-align: center;
			font-size: px2rem(32);
			font-family: PingFang SC-Semibold, PingFang SC;
			font-weight: 600;
			color: #333333;
			line-height: px2rem(38);
		}

		.top_number {
			margin-top: px2rem(22);
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				font-size: px2rem(28);
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
			}

			span:nth-child(1) {
				color: #999999;
			}

			span:nth-child(2) {
				color: #333333;
			}

			div {
				margin-left: px2rem(16);
				width: px2rem(96);
				height: px2rem(40);
				line-height: px2rem(40);
				font-size: px2rem(24);
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #ffffff;
				background: #122ab0;
				border-radius: px2rem(20);
			}
		}
	
		.uls {
			width: 100%;
			background: #ffffff;
			margin-top: px2rem(48);
			padding-left: px2rem(32);
			padding-right: px2rem(32);
			box-sizing: border-box;
			margin-bottom: px2rem(18);
			border-radius: px2rem(16);
		
			.lis {
				width: 100%;
				height: px2rem(104);
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: px2rem(28);
				font-family: PingFang SC-Regular, PingFang SC;
		
				span {
					font-size: px2rem(28);
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400; 
					color: #999999;
					white-space: nowrap;
				}
				
				div{
					font-size: px2rem(28);
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400; 
					color: #333333;
					white-space: nowrap;
				}
		 
			}
		
			.lis_hr {
				position: relative;
			}
			.lis_hr::after {
				content: ' ';
				width: 100%;
				height: px2rem(2);
				background: #f2f2f2;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0%;
				margin: auto;
			}
		}
	}
}
</style>
